export enum LanguageCode {
  Czech = 'cs',
  German = 'de',
  English = 'en',
  Spanish = 'es',
  French = 'fr',
  Italian = 'it',
  Dutch = 'nl',
  Polish = 'pl',
  PortugueseBrazil = 'pt',
  Finnish = 'fi',
  Swedish = 'sv',
  Vietnamese = 'vi',
  Turkish = 'tr',
  Russian = 'ru',
  Hebrew = 'he',
  Arabic = 'ar',
  Persian = 'fa',
  Thai = 'th',
  Korean = 'ko',
  ChineseSimplified = 'zh',
  ChineseTraditional = 'zh-TW',
  Japanese = 'ja',
  PSEUDO = 'pseudo',
  CIMODE = 'cimode',
}

export const stringToLanguageCode = (
  s: string | undefined
): LanguageCode | undefined => {
  if (!s) {
    return undefined
  }
  if (Object.values(LanguageCode).includes(s as LanguageCode)) {
    return s as LanguageCode
  }
  return LanguageCode.English
}
