type Environment = 'dev' | 'stage' | 'prod'

interface Config {
  acxClientKey: string
  applicationArn: string
  discoveryApiUrl: string
  environment: Environment
  globalAdminRoleId: string
  loginUrl: string
  matomo: {
    endpoint: string
    siteId: string
  }
  region: string
  regionApiEndpoint: string
  userAccountUrl: string
}

const environments: Record<Environment, Config> = {
  dev: {
    acxClientKey: '3138af13f04691370ff277e4fda421e0',
    applicationArn: 'arn:application:::c01d4dc3-f805-43ec-8cb2-1460dafd8a09',
    discoveryApiUrl:
      'https://discovery.api.dev.bodyworn.axis.com/v1/discovery/hostingInstances/dev',
    environment: 'dev',
    loginUrl: 'https://stage.login.connect.axis.com',
    matomo: {
      endpoint: 'https://metrics-stage-web.se.axis.com/matomo.php',
      siteId: '27',
    },
    region: 'dev',
    regionApiEndpoint: 'https://api.az.bws.axis.cloud/v1/regions',
    userAccountUrl: 'https://auth-stage.axis.com/user-center/account',
    globalAdminRoleId: '1046ae3c-aab3-4d1a-a7a9-9925d4409983',
  },
  stage: {
    acxClientKey: '3138af13f04691370ff277e4fda421e0',
    applicationArn: 'arn:application:::c01d4dc3-f805-43ec-8cb2-1460dafd8a09',
    discoveryApiUrl:
      'https://discovery.api.stage.bodyworn.axis.com/v1/discovery/hostingInstances/stage',
    environment: 'stage',
    loginUrl: 'https://stage.login.connect.axis.com',
    matomo: {
      endpoint: 'https://metrics-stage-web.se.axis.com/matomo.php',
      siteId: '27',
    },
    region: 'stage',
    regionApiEndpoint: 'https://api.bodyworn-stage.axis.cloud/v1/regions',
    userAccountUrl: 'https://auth-stage.axis.com/user-center/account',
    globalAdminRoleId: '1046ae3c-aab3-4d1a-a7a9-9925d4409983',
  },
  prod: {
    acxClientKey: '7cb7db02c80cfcee62c5fded3b23578b',
    applicationArn: 'arn:application:::c01d4dc3-f805-43ec-8cb2-1460dafd8a09',
    discoveryApiUrl:
      'https://discovery.api.prod.bodyworn.axis.com/v1/discovery/hostingInstances/prod',
    environment: 'prod',
    loginUrl: 'https://eu.login.connect.axis.com',
    matomo: {
      endpoint: 'https://metrics.axis.com/matomo.php',
      siteId: '16',
    },
    region: 'de',
    regionApiEndpoint: 'https://api.bodyworn.axis.cloud/v1/regions',
    userAccountUrl: 'https://auth.axis.com/user-center/account',
    globalAdminRoleId: 'cc4ad012-cdb4-4248-bb54-984ceb2cada3',
  },
}

export let config: Config = {} as Config

interface SetConfigOptions {
  environment: Environment
}

export function setConfig({ environment }: SetConfigOptions) {
  config = environments[environment]
}

export function isDev(hostname: string) {
  return (
    hostname === 'localhost' ||
    hostname === '127.0.0.1' ||
    hostname.endsWith('local.bodyworn.axis.com') ||
    hostname.endsWith('.azurestaticapps.net') // PR previews
  )
}

export function getEnvironment(hostname: string): Environment {
  const prod =
    (hostname.endsWith('bw.mysystems.axis.com') ||
      hostname.endsWith('prod.bodyworn.axis.com')) &&
    'prod'
  const stage = hostname.endsWith('stage.bodyworn.axis.com') && 'stage'
  const dev = hostname.endsWith('dev.bodyworn.axis.com') && 'dev'
  const local = isDev(hostname) && 'dev'
  const environment = prod || stage || dev || local
  if (!environment) {
    throw new Error('Unknown environment')
  }
  return environment
}
