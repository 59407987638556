import {
  mergeClasses,
  useArrowNavigationGroup,
} from '@fluentui/react-components'
import React from 'react'

import { TreeNode } from './tree-node'
import { useTreeStyles } from './tree.styles'
import { TreeProps } from './tree.types'

export const treeNodeClassNames: Record<'root', string> = {
  root: 'axis-Tree',
}

export const Tree = <T extends object = object>({
  data,
  onClick,
  onRenderNode,
  showIndentLines = true,
  combinedExpandAction = true,
}: TreeProps<T>) => {
  const styles = useTreeStyles()
  const focusAttributes = useArrowNavigationGroup({ circular: true })

  const rootStyle = mergeClasses(treeNodeClassNames.root, styles.root)

  return (
    <div className={rootStyle} {...focusAttributes}>
      {data.map((d) => (
        <TreeNode<T>
          key={d.id}
          data={d}
          onClick={onClick}
          onRenderNode={onRenderNode}
          showIndentLines={showIndentLines}
          combinedExpandAction={combinedExpandAction}
        />
      ))}
    </div>
  )
}
Tree.displayName = 'Tree'
