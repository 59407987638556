import { ErrorCode } from '../graphql/__generated__/graphql'

export enum InternalErrorCode {
  NetworkError = 'NETWORK_ERROR',
}

export enum ApiStatus {
  Idle,
  Loading,
  Resolved,
  Rejected,
}

export type ApiResult<T> =
  | {
      status: ApiStatus.Idle
    }
  | {
      status: ApiStatus.Loading
    }
  | {
      status: ApiStatus.Resolved
      data: T
    }
  | {
      status: ApiStatus.Rejected
      error: ErrorCode | InternalErrorCode
    }
