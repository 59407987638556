import {
  createFocusOutlineStyle,
  makeStyles,
  tokens,
} from '@fluentui/react-components'

export const useTreeNodeStyles = makeStyles({
  node: {
    position: 'relative',
    ...createFocusOutlineStyle(),
    display: 'flex',
    userSelect: 'none',
    alignItems: 'center',
  },
  hover: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: tokens.colorNeutralBackground1Hover,
      borderRadius: tokens.borderRadiusMedium,
    },
  },
  content: {
    flexGrow: 1,
    paddingTop: tokens.spacingVerticalXXS,
    paddingBottom: tokens.spacingVerticalXXS,
    paddingRight: tokens.spacingHorizontalNone,
    paddingLeft: tokens.spacingHorizontalXS,
  },
  data: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: tokens.spacingHorizontalSNudge,
    marginLeft: tokens.spacingHorizontalS,
  },
  dashedIndentLines: {
    borderLeft: `${tokens.strokeWidthThin} dashed ${tokens.colorNeutralForeground1}`,
  },
  chevron: {
    display: 'flex',
    alignItems: 'center',
    padding: `${tokens.spacingVerticalXXS} 0`,
  },
})
