import {
  ActivateApplicationDocument,
  ActivateApplicationMutation,
  ErrorCode,
} from '../graphql/__generated__/graphql'
import { ApiStatus, InternalErrorCode } from './types'
import { Api, useUrqlClient } from './useUrqlClient'

type ActivateApplicationProps = {
  applicationArn: string
  organizationArn: string
}

export function useActivateApplication() {
  const client = useUrqlClient(Api.Acx)

  return function activateApplication({
    applicationArn,
    organizationArn,
  }: ActivateApplicationProps) {
    return client
      ?.mutation<ActivateApplicationMutation>(ActivateApplicationDocument, {
        input: {
          applicationArn: applicationArn,
          organizationArn: organizationArn,
        },
      })
      .toPromise()
      .then((result) => {
        if (result.error) {
          const errorCode =
            (result.error.graphQLErrors[0]?.extensions.code as ErrorCode) ??
            InternalErrorCode.NetworkError
          return {
            status: ApiStatus.Rejected,
            error: errorCode,
          }
        } else {
          return {
            status: ApiStatus.Resolved,
          }
        }
      })
  }
}
