import {
  Body1,
  Button,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuItemRadio,
  MenuList,
  MenuOpenChangeData,
  MenuOpenEvent,
  MenuPopover,
  MenuTrigger,
  Skeleton,
  SkeletonItem,
  Spinner,
  Tooltip,
  makeStyles,
  tokens,
  useRestoreFocusTarget,
} from '@fluentui/react-components'
import {
  Add24Regular,
  BuildingMultiple24Regular,
  Checkmark20Regular,
  Info16Regular,
  Warning20Filled,
} from '@fluentui/react-icons'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { CreateOrganizationDialog } from './CreateOrganizationDialog'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  skeleton: {
    width: '400px',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    columnGap: tokens.spacingHorizontalS,
  },
  dialogTriggerButton: {
    width: '400px',
  },
  dialogTriggerButtonText: {
    width: '100%',
    justifySelf: 'start',
  },
  menuButton: {
    width: '400px',
  },
  menuButtonText: {
    flexGrow: 1,
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
  },
  menuPopover: {
    width: '400px',
    minWidth: '400px',
    overflowY: 'auto',
    zIndex: 100,
  },
  menuItem: {
    width: '400px',
    minWidth: '100%',
  },
  icon: {
    flexShrink: 0,
  },
})

export type Organization = {
  id: string
  name: string
}

type OrganizationStepProps = {
  loading: boolean
  onCreateOrganization: (organizationName: string) => void
  onSetOrganization: (organization: Organization) => void
  organization?: Organization
  organizations?: Organization[]
  showWarning: boolean
  getOrganizations: () => void
  adminStatusLoading: boolean
}

function OrganizationStep({
  loading,
  onCreateOrganization,
  onSetOrganization,
  organization,
  organizations,
  showWarning,
  getOrganizations,
  adminStatusLoading,
}: OrganizationStepProps) {
  const styles = useStyles()
  const restoreFocusTargetAttribute = useRestoreFocusTarget()
  const [menuOpen, setMenuOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const { t } = useTranslation()

  if (loading) {
    return (
      <div className={styles.container}>
        <OrganizationHeading />
        <Skeleton
          className={styles.skeleton}
          aria-label={t('loading-organizations')}
        >
          <SkeletonItem size={40} appearance="translucent" />
        </Skeleton>
      </div>
    )
  }

  if (organizations && organizations.length === 0) {
    return (
      <div className={styles.container}>
        <OrganizationHeading />
        <Button
          className={styles.dialogTriggerButton}
          appearance="secondary"
          size="large"
          icon={<Add24Regular color={tokens.colorNeutralStrokeAccessible} />}
          onClick={() => setDialogOpen(true)}
          data-testId={'CreateNewOrganizationButton'}
        >
          <Body1 className={styles.dialogTriggerButtonText}>
            {t('create-new-organization')}
          </Body1>
        </Button>
        <CreateOrganizationDialog
          open={dialogOpen}
          onOpenChange={setDialogOpen}
          onCreateOrganization={onCreateOrganization}
        />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <OrganizationHeading />
      <div className={styles.content}>
        <OrganizationMenu />
        <CreateOrganizationDialog
          open={dialogOpen}
          onOpenChange={setDialogOpen}
          onCreateOrganization={onCreateOrganization}
        />
        {organization && (
          <OrganizationStatusIcon
            showWarning={showWarning}
            loading={adminStatusLoading}
          />
        )}
      </div>
    </div>
  )

  function OrganizationMenu() {
    const styles = useStyles()
    const organizationMenuOptions = organizations?.map(menuItemMapper)

    return (
      <Menu
        inline
        positioning={{
          position: 'below',
          align: 'end',
          pinned: true,
          autoSize: 'height',
          overflowBoundaryPadding: { bottom: 25 },
        }}
        open={menuOpen}
        onOpenChange={onOpenChange}
        checkedValues={{ organization: [organization?.id ?? ''] }}
        data-testid={'organizationMenu'}
      >
        <MenuTrigger>
          <MenuButton
            className={styles.menuButton}
            appearance="secondary"
            size="large"
            icon={
              <BuildingMultiple24Regular
                color={tokens.colorNeutralStrokeAccessible}
              />
            }
            data-testid={'organizationMenuButton'}
          >
            <Body1 className={styles.menuButtonText}>
              {organization?.name ?? t('select-organization')}
            </Body1>
          </MenuButton>
        </MenuTrigger>
        <MenuPopover className={styles.menuPopover}>
          <MenuList>
            {organizationMenuOptions}
            <MenuDivider />
            <MenuItem
              className={styles.menuItem}
              {...restoreFocusTargetAttribute}
              icon={<Add24Regular />}
              onClick={() => setDialogOpen(true)}
              data-testid={'createNewOrganizationMenuItem'}
            >
              {t('create-new-organization')}
            </MenuItem>
          </MenuList>
        </MenuPopover>
      </Menu>
    )
  }

  function menuItemMapper(organization: Organization) {
    return (
      <MenuItemRadio
        className={styles.menuItem}
        key={organization.id}
        name="organization"
        value={organization.id}
        onClick={() =>
          onSetOrganization({
            id: organization.id,
            name: organization.name,
          })
        }
        data-testid={organization.name + 'MenuItem'}
      >
        {organization.name}
      </MenuItemRadio>
    )
  }

  function onOpenChange(_event: MenuOpenEvent, data: MenuOpenChangeData) {
    setMenuOpen(data.open)
    if (data.open) {
      getOrganizations()
    }
  }
}

function OrganizationHeading() {
  const { t } = useTranslation()
  return (
    <h4>
      {t('organization')}
      <Tooltip content={t('organization-heading-tooltip')} relationship="label">
        <Button appearance="transparent" icon={<Info16Regular />} />
      </Tooltip>
    </h4>
  )
}

type StatusIconProps = {
  showWarning: boolean
  loading: boolean
}

function OrganizationStatusIcon({ showWarning, loading }: StatusIconProps) {
  if (loading) {
    return (
      <Spinner
        size="tiny"
        color={tokens.colorBrandForeground1}
        data-testid="organizationSpinnerIcon"
      />
    )
  }

  if (showWarning) {
    return (
      <Warning20Filled
        color={tokens.colorStatusWarningForeground3}
        data-testid="organizationWarningIcon"
      />
    )
  }

  return (
    <Checkmark20Regular
      color={tokens.colorBrandForeground1}
      data-testid="organizationCheckmarkIcon"
    />
  )
}

export { OrganizationStep }
