import {
  Link,
  Text,
  Tooltip,
  makeStyles,
  mergeClasses,
} from '@fluentui/react-components'
import { OpenRegular } from '@fluentui/react-icons'

type ExternalLinkProps = {
  className?: string
  href: string
  text: string
  tooltipText?: string
  icon?: boolean
  target?: '_self' | '_blank' | '_parent' | '_top'
  'data-testid': string
}

const useStyles = makeStyles({
  link: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  text: {
    marginInlineEnd: '.2em',
  },
  icon: {
    flexShrink: 0,
    marginBlockStart: '2px', // Magic number to improve visual alignment
  },
})

export function ExternalLink({
  className,
  href,
  text,
  tooltipText,
  icon = true,
  target = '_blank',
  'data-testid': dataTestId,
}: ExternalLinkProps) {
  const styles = useStyles()
  const classes = mergeClasses(className, styles.link)
  const link = (
    <Link
      href={href}
      className={classes}
      target={target}
      data-testid={dataTestId}
    >
      <Text className={styles.text}>{text}</Text>
      {icon && <OpenRegular className={styles.icon} />}
    </Link>
  )

  return tooltipText ? (
    <Tooltip content={tooltipText} relationship={'description'}>
      {link}
    </Tooltip>
  ) : (
    link
  )
}
