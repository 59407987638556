import {
  CreateAccessDocument,
  CreateAccessMutation,
  ErrorCode,
} from '../graphql/__generated__/graphql'
import { ApiStatus, InternalErrorCode } from './types'
import { Api, useUrqlClient } from './useUrqlClient'

type CreateAccessParams = {
  principalArn: string
  roleId: string
  targetArn: string
}

export function useCreateAccess() {
  const client = useUrqlClient(Api.Acx)

  return function createAccess({
    principalArn,
    roleId,
    targetArn,
  }: CreateAccessParams) {
    return client
      ?.mutation<CreateAccessMutation>(CreateAccessDocument, {
        input: {
          principalArns: [principalArn],
          roleId: roleId,
          targetArn: targetArn,
        },
      })
      .toPromise()
      .then((result) => {
        if (result.error) {
          const errorCode =
            (result.error.graphQLErrors[0]?.extensions.code as ErrorCode) ??
            InternalErrorCode.NetworkError
          return {
            status: ApiStatus.Rejected,
            error: errorCode,
          }
        } else {
          return {
            status: ApiStatus.Resolved,
          }
        }
      })
  }
}
