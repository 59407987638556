import {
  ErrorCode,
  StartTrialDocument,
  StartTrialMutation,
} from '../graphql/__generated__/graphql'
import { ApiStatus, InternalErrorCode } from './types'
import { PRODUCT_NAME_BWL } from './useLicenseInfo'
import { Api, useUrqlClient } from './useUrqlClient'

export function useStartTrial() {
  const client = useUrqlClient(Api.Bwo)

  return function (organizationId: string) {
    return client
      ?.mutation<StartTrialMutation>(StartTrialDocument, {
        input: {
          organizationId,
          product: PRODUCT_NAME_BWL,
        },
      })
      .toPromise()
      .then((result) => {
        if (result.error) {
          const errorCode =
            (result.error.graphQLErrors[0]?.extensions.code as ErrorCode) ??
            InternalErrorCode.NetworkError
          return {
            status: ApiStatus.Rejected,
            error: errorCode,
          }
        } else {
          return {
            status: ApiStatus.Resolved,
          }
        }
      })
  }
}
