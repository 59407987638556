import {
  Body1Strong,
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Input,
  InputOnChangeData,
  Label,
  makeStyles,
  tokens,
} from '@fluentui/react-components'
import { Add24Regular } from '@fluentui/react-icons'
import { ChangeEvent, FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  dialogTriggerButton: {
    width: '400px',
  },
  dialogTriggerButtonText: {
    flexGrow: 1,
  },
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalM,
  },
  dialogActions: {
    justifyContent: 'end',
  },
})

type CreateResourceGroupDialogProps = {
  disabled: boolean
  onCreateResourceGroup: (resourceGroupName: string) => void
}

export function CreateResourceGroupDialog({
  disabled,
  onCreateResourceGroup,
}: CreateResourceGroupDialogProps) {
  const styles = useStyles()
  const { t } = useTranslation()
  const [resourceGroupName, setResourceGroupName] = useState('')

  return (
    <Dialog>
      <DialogTrigger disableButtonEnhancement>
        <Button
          className={styles.dialogTriggerButton}
          appearance="secondary"
          size="large"
          icon={<Add24Regular />}
          disabled={disabled}
          data-testid="createNewFolderButton"
        >
          <Body1Strong className={styles.dialogTriggerButtonText}>
            {t('create-new-folder')}
          </Body1Strong>
        </Button>
      </DialogTrigger>
      <DialogSurface>
        <form onSubmit={handleSubmit}>
          <DialogBody className={styles.dialogBody}>
            <DialogTitle>{t('create-folder')}</DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <Label htmlFor={'resource-group-input'}>{t('folder-name')}</Label>
              <Input
                id="resource-group-input"
                onChange={handleChange}
                value={resourceGroupName}
                data-testid="createNewFolderNameInput"
              />
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">{t('action--cancel')}</Button>
              </DialogTrigger>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  type="submit"
                  appearance="primary"
                  disabled={!resourceGroupName}
                  data-testid="createNewFolderCreateButton"
                >
                  {t('action--create')}
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    onCreateResourceGroup(resourceGroupName)
    setResourceGroupName('')
  }

  function handleChange(
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) {
    setResourceGroupName(data.value)
  }
}
