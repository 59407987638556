import React, { createContext, useContext } from 'react'

import { DispatchAlert, useAlerts } from '../hooks/useAlerts'

type AlertContextProps = {
  renderedAlerts: JSX.Element[]
  dispatchAlert: DispatchAlert
}

const AlertContext = createContext<AlertContextProps>({
  renderedAlerts: [],
  dispatchAlert() {
    throw new Error('You forgot to wrap your component in <AlertProvider>.')
  },
})

type AlertProviderProps = {
  children: React.ReactNode
}

function AlertProvider({ children }: AlertProviderProps) {
  const { renderedAlerts, dispatchAlert } = useAlerts()

  const alertState: AlertContextProps = {
    renderedAlerts,
    dispatchAlert,
  }

  return (
    <AlertContext.Provider value={alertState}>{children}</AlertContext.Provider>
  )
}

function useAlertContext() {
  const { renderedAlerts, dispatchAlert } = useContext(AlertContext)
  return { renderedAlerts, dispatchAlert }
}

export { AlertProvider, useAlertContext as useAlerts }
