import { Spinner, makeStyles } from '@fluentui/react-components'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

function LoadingPage() {
  const styles = useStyles()
  return (
    <div className={styles.container}>
      <Spinner />
    </div>
  )
}

export { LoadingPage }
