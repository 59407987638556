import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Input,
  InputOnChangeData,
  Label,
  makeStyles,
  tokens,
} from '@fluentui/react-components'
import { ChangeEvent, FormEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles({
  dialogBody: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalL,
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: tokens.spacingVerticalM,
  },
  dialogActions: {
    justifyContent: 'end',
  },
})

type CreateOrganizationDialogProps = {
  open: boolean
  onOpenChange: (open: boolean) => void
  onCreateOrganization: (organizationName: string) => void
}

export function CreateOrganizationDialog({
  open,
  onOpenChange,
  onCreateOrganization,
}: CreateOrganizationDialogProps) {
  const styles = useStyles()
  const { t } = useTranslation()
  const [organizationName, setOrganizationName] = useState('')

  return (
    <Dialog
      open={open}
      onOpenChange={(_event, data) => onOpenChange(data.open)}
    >
      <DialogSurface>
        <form onSubmit={handleSubmit}>
          <DialogBody className={styles.dialogBody}>
            <DialogTitle>{t('create-organization')}</DialogTitle>
            <DialogContent className={styles.dialogContent}>
              <Label htmlFor={'organization-input'}>
                {t('organization-name')}
              </Label>
              <Input
                id="organization-input"
                onChange={handleChange}
                value={organizationName}
                data-testid="newOrganizationNameInput"
              />
            </DialogContent>
            <DialogActions className={styles.dialogActions}>
              <DialogTrigger disableButtonEnhancement>
                <Button appearance="secondary">{t('action--cancel')}</Button>
              </DialogTrigger>
              <DialogTrigger disableButtonEnhancement>
                <Button
                  type="submit"
                  appearance="primary"
                  disabled={!organizationName}
                  data-testid="newOrganizationCreateButton"
                >
                  {t('action--create')}
                </Button>
              </DialogTrigger>
            </DialogActions>
          </DialogBody>
        </form>
      </DialogSurface>
    </Dialog>
  )

  function handleSubmit(event: FormEvent) {
    event.preventDefault()
    onCreateOrganization(organizationName)
    setOrganizationName('')
  }

  function handleChange(
    _event: ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) {
    setOrganizationName(data.value)
  }
}
