import { useCallback } from 'react'

import {
  CreateResourceGroupDocument,
  CreateResourceGroupMutation,
  ErrorCode,
} from '../graphql/__generated__/graphql'
import { ApiStatus, InternalErrorCode } from './types'
import { Api, useUrqlClient } from './useUrqlClient'

type CreateResourceGroupParams = {
  description: string
  name: string
  parentArn: string
}

export function useCreateResourceGroup() {
  const client = useUrqlClient(Api.Acx)

  return useCallback(
    ({ description, name, parentArn }: CreateResourceGroupParams) => {
      return client
        ?.mutation<CreateResourceGroupMutation>(CreateResourceGroupDocument, {
          input: {
            description: description,
            name: name,
            parentArn: parentArn,
          },
        })
        .toPromise()
        .then((result) => {
          if (result.error) {
            const errorCode =
              (result.error.graphQLErrors[0]?.extensions.code as ErrorCode) ??
              InternalErrorCode.NetworkError
            return {
              status: ApiStatus.Rejected,
              error: errorCode,
            }
          } else {
            return {
              status: ApiStatus.Resolved,
              data: result.data,
            }
          }
        })
    },
    [client]
  )
}
