import { mergeClasses, useFluent } from '@fluentui/react-components'
import {
  ChevronDown16Regular,
  ChevronLeft16Regular,
  ChevronRight16Regular,
} from '@fluentui/react-icons'
import { isInteractiveHTMLElement } from '@fluentui/react-utilities'
import React, { useCallback, useState } from 'react'

import { TreeNodeData } from '../tree.types'
import { useTreeNodeStyles } from './tree-node.styles'
import { TreeNodeProps } from './tree-node.types'

export const treeNodeClassNames = {
  root: 'axis-TreeNode',
  content: 'axis-TreeNode__content',
  expanded: 'axis-TreeNode_expanded',
} as const
const defaultRenderNode = <T extends object>(node: TreeNodeData<T>) => {
  return <>{node.title ?? node.id}</>
}

export const TreeNode = <T extends object = object>({
  data,
  onClick,
  onRenderNode,
  showIndentLines = true,
  combinedExpandAction = true,
}: TreeNodeProps<T>) => {
  const [expanded, setExpanded] = useState(data.defaultExpanded ?? false)
  const onToggle = useCallback(() => setExpanded((s) => !s), [])
  const styles = useTreeNodeStyles()
  const { dir } = useFluent()
  const hasChildren = Boolean(data.children?.length)
  const hasAction = Boolean(onClick || hasChildren)

  const rootStyle = mergeClasses(
    treeNodeClassNames.root,
    styles.node,
    combinedExpandAction && hasAction && styles.hover
  )

  const contentStyle = mergeClasses(
    treeNodeClassNames.content,
    styles.content,
    !combinedExpandAction && hasAction && styles.hover
  )

  const expandedStyle = mergeClasses(
    treeNodeClassNames.expanded,
    styles.data,
    showIndentLines && styles.dashedIndentLines
  )

  const chevronStyle = mergeClasses(
    styles.chevron,
    !combinedExpandAction && styles.hover
  )

  const handleChevronClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if (hasChildren) {
        onToggle()
        e.preventDefault()
      }
      if (combinedExpandAction) {
        onClick?.(data)
      }
    },
    [hasChildren, onToggle, onClick, data, combinedExpandAction]
  )

  const handleClick = useCallback(() => {
    if (hasChildren && combinedExpandAction) {
      onToggle()
    }
    onClick?.(data)
  }, [hasChildren, onToggle, onClick, data, combinedExpandAction])

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      // NOTE: This is marked as an internal tool and should probably not be used?
      if (isInteractiveHTMLElement(e)) {
        e.preventDefault()
        ;(e.target as HTMLElement)?.click()
      }
    },
    []
  )

  const Chevron = () => {
    return expanded ? (
      <ChevronDown16Regular />
    ) : dir === 'rtl' ? (
      <ChevronLeft16Regular />
    ) : (
      <ChevronRight16Regular />
    )
  }

  return (
    <div>
      <div className={rootStyle} tabIndex={0} onKeyDown={handleKeyDown}>
        {hasChildren && (
          <div
            data-testid={'tree-node-chevron-' + data.id}
            className={chevronStyle}
            onClick={handleChevronClick}
          >
            <Chevron />
          </div>
        )}
        <div
          data-testid={'tree-node-content-' + data.id}
          className={contentStyle}
          onClick={handleClick}
        >
          {(data.onRenderNode ?? onRenderNode ?? defaultRenderNode)(data)}
        </div>
      </div>
      {expanded && (
        <div className={expandedStyle}>
          {data.children?.map((d) => (
            <TreeNode
              key={d.id}
              data={d}
              onClick={onClick}
              onRenderNode={onRenderNode}
              showIndentLines={showIndentLines}
              combinedExpandAction={combinedExpandAction}
            />
          ))}
        </div>
      )}
    </div>
  )
}
