import {
  ErrorCode,
  GetOrganizationPrincipalDocument,
  GetOrganizationPrincipalQuery,
} from '../graphql/__generated__/graphql'
import { ApiStatus, InternalErrorCode } from './types'
import { Api, useUrqlClient } from './useUrqlClient'

export type OrganizationPrincipal = {
  name: string
  email: string
  arn: string
}

type OrganizationPrincipalParams = {
  organizationId: string
}

export function useOrganizationPrincipal() {
  const client = useUrqlClient(Api.Acx)

  return function organizationPrincipal({
    organizationId,
  }: OrganizationPrincipalParams) {
    return client
      ?.query<GetOrganizationPrincipalQuery>(
        GetOrganizationPrincipalDocument,
        {
          organizationArn: `arn:organization:${organizationId}`,
        },
        { requestPolicy: 'network-only' }
      )
      .toPromise()
      .then((result) => {
        if (result.error) {
          const errorCode =
            (result.error.graphQLErrors[0]?.extensions.code as ErrorCode) ??
            InternalErrorCode.NetworkError
          return {
            status: ApiStatus.Rejected,
            error: errorCode,
          }
        } else {
          return {
            status: ApiStatus.Resolved,
            data: result.data,
          }
        }
      })
  }
}
