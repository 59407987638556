import { makeStyles, tokens } from '@fluentui/react-components'
import { Alert } from '@fluentui/react-components/unstable'
import { DismissCircle20Regular } from '@fluentui/react-icons'
import { useCallback, useState } from 'react'

const useStyles = makeStyles({
  alert: {
    backgroundColor: tokens.colorNeutralBackground4,
    width: '90%',
    '@media (min-width: 600px)': {
      width: '50%',
    },
  },
})

/** Options for dispatching an alert. */
export interface AlertOptions {
  message: string
  intent?: 'info' | 'success' | 'error' | 'warning'
  /** The number of milliseconds after which the alert is automatically hidden. */
  hideAfter?: number
  /**
   * Whether or not the alert is unique.
   * A unique alert will only ever be shown if no other alert has the same message.
   */
  unique?: boolean
}

export interface Alert {
  id: string
  options: AlertOptions
  timeout?: NodeJS.Timeout // TODO: Browser
}

/** Dispatch an alert. */
export interface DispatchAlert {
  (options: AlertOptions): void
}

function generateRandomId(): string {
  return Math.floor(Math.random() * 1e6).toString()
}

/**
 * An alert management hook.
 * The renderedAlerts of the result contains alert components ready for display.
 * The dispatchAlert function is used to dispatch alerts.
 * @example
 * const {renderedAlerts, dispatchAlert} = useAlerts()
 * useEffect(() => {
 *   dispatchAlert({
 *     message: 'Hello, World!',
 *     intent: 'info',
 *     hideAfter: 3 * 1e3,
 *   })
 * }, [])
 * return renderedAlerts
 */
export function useAlerts(): {
  renderedAlerts: JSX.Element[]
  dispatchAlert: DispatchAlert
} {
  const [alerts, setAlerts] = useState<Alert[]>([])
  const styles = useStyles()

  function handleAlertAction(alert: Alert) {
    removeAlert(alert)
  }

  const removeAlert = useCallback((alert: Alert) => {
    // Clear any potential auto hide timeout
    if (alert.timeout) {
      clearTimeout(alert.timeout)
      alert.timeout = undefined
    }

    setAlerts((alerts) => alerts.filter((x) => x.id !== alert.id))
  }, [])

  const dispatchAlert = useCallback(
    (options: AlertOptions) => {
      const alert: Alert = {
        id: options.unique ? options.message : generateRandomId(),
        options,
      }

      if (options.hideAfter) {
        alert.timeout = setTimeout(() => {
          removeAlert(alert)
        }, options.hideAfter)
      }

      setAlerts((alerts) => {
        // Use the correct state to identify whether or not a duplicate exists
        const exists = alerts.find((x) => x.id === alert.id)
        return exists ? alerts : [...alerts, alert]
      })
    },
    [removeAlert]
  )

  const renderedAlerts = alerts.map((alert, i) => (
    <Alert
      className={styles.alert}
      key={i}
      intent={alert.options.intent}
      action={{
        icon: <DismissCircle20Regular aria-label="dismiss message" />,
        onClick: () => {
          handleAlertAction(alert)
        },
      }}
    >
      {alert.options.message}
    </Alert>
  ))

  return { renderedAlerts, dispatchAlert }
}
