import { CodeErrorDark } from '@axiscommunications/fluent-illustrations'
import { makeStyles, tokens } from '@fluentui/react-components'

import { Illustration } from '../components/Illustration'

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    '@supports (height: 100dvh)': {
      height: '100dvh',
    },
    alignItems: 'center',
    justifyContent: 'center',
    gap: tokens.spacingVerticalL,
  },
})

type InternalErrorPageProps = {
  message?: string
}

function InternalErrorPage({ message }: InternalErrorPageProps) {
  const styles = useStyles()

  return (
    <div className={styles.container}>
      <Illustration
        title="Internal Error"
        description={message && message}
        illustration={CodeErrorDark}
      />
    </div>
  )
}

export { InternalErrorPage }
