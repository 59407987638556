import { Temporal } from '@js-temporal/polyfill'
import { useEffect, useState } from 'react'
import { Client } from 'urql'

import {
  ErrorCode,
  GetLicenseInfoDocument,
  GetLicenseInfoQuery,
  LicenseType,
} from '../graphql/__generated__/graphql'
import { ApiResult, ApiStatus, InternalErrorCode } from './types'

/** Product shortname for Body Worn Live in AXIS License Manager. */
export const PRODUCT_NAME_BWL = 'abwl'

export type LicenseInfo = {
  licensed: boolean
  licenses: License[]
  product: string
}

export type License = {
  expire: Temporal.Instant
  type: LicenseType
}

export function useLicenseInfo(
  client?: Client,
  organizationId?: string,
  product?: string
): ApiResult<LicenseInfo> {
  const [result, setResult] = useState<ApiResult<LicenseInfo>>({
    status: ApiStatus.Idle,
  })

  useEffect(() => {
    if (!client || !organizationId || !product) {
      return
    }

    setResult({ status: ApiStatus.Loading })

    client
      .query<GetLicenseInfoQuery>(GetLicenseInfoDocument, {
        organizationId,
        product,
      })
      .toPromise()
      .then((result) => {
        if (result.error) {
          const errorCode =
            (result.error.graphQLErrors[0]?.extensions.code as ErrorCode) ??
            InternalErrorCode.NetworkError
          setResult({
            status: ApiStatus.Rejected,
            error: errorCode,
          })
        } else {
          const data = result.data?.getLicenseInfo
          if (data) {
            const licenseInfo = licenseInfoMapper(data)
            setResult({
              status: ApiStatus.Resolved,
              data: licenseInfo,
            })
          }
        }
      })
  }, [client, organizationId, product])

  return result
}

function licenseInfoMapper(
  data: GetLicenseInfoQuery['getLicenseInfo']
): LicenseInfo {
  return {
    product: data.product,
    licensed: data.licensed,
    licenses: data.licenses.map((l) => {
      return {
        expire: Temporal.Instant.from(l.expires),
        type: l.type,
      }
    }),
  }
}
