import { AuthStatus, useAuth } from '@axteams-one/auth-provider'
import { useApi } from '@axteams-one/bws-cloud-discovery/react'
import { authExchange } from '@urql/exchange-auth'
import { useEffect, useState } from 'react'
import { Client, cacheExchange, createClient, fetchExchange } from 'urql'

import { config } from '../config'

export enum Api {
  Bwo,
  Acx,
}

function useUrqlClient(api: Api) {
  const { refresh, status } = useAuth()
  const [urqlClient, setUrqlClient] = useState<Client>()

  const bwoApi = useApi('bwo')
  const acxApi = useApi('acx-graphql')

  // See https://confluence.se.axis.com/display/IAM/API+Keys
  useEffect(() => {
    if (!bwoApi || !acxApi || status === AuthStatus.Uninitialized) {
      return
    }

    const url = () => {
      switch (api) {
        case Api.Acx:
          return `${acxApi.uri}?acx-client-key=${config.acxClientKey}`
        default:
          return bwoApi.uri
      }
    }

    const client = createClient({
      url: url(),
      exchanges: [
        cacheExchange,
        authExchange(async (utils) => {
          const response = await refresh()
          let token = response.data?.access_token
          return {
            addAuthToOperation(operation) {
              if (!token) {
                return operation
              }
              return utils.appendHeaders(operation, {
                Authorization: `Bearer ${token}`,
              })
            },
            didAuthError() {
              return true
            },
            async refreshAuth() {
              const response = await refresh()
              token = response.data?.access_token
            },
          }
        }),
        fetchExchange,
      ],
    })

    setUrqlClient(client)
  }, [acxApi, api, bwoApi, refresh, status])

  return urqlClient
}

export { useUrqlClient }
