import {
  CreateOrganizationDocument,
  CreateOrganizationMutation,
  ErrorCode,
} from '../graphql/__generated__/graphql'
import { ApiStatus, InternalErrorCode } from './types'
import { Api, useUrqlClient } from './useUrqlClient'

type CreateOrganizationParams = {
  name: string
}

export function useCreateOrganization() {
  const client = useUrqlClient(Api.Acx)

  return function createOrganization({ name }: CreateOrganizationParams) {
    return client
      ?.mutation<CreateOrganizationMutation>(CreateOrganizationDocument, {
        input: {
          name: name,
        },
      })
      .toPromise()
      .then((result) => {
        if (result.error) {
          const errorCode =
            (result.error.graphQLErrors[0]?.extensions.code as ErrorCode) ??
            InternalErrorCode.NetworkError
          return {
            status: ApiStatus.Rejected,
            error: errorCode,
          }
        } else {
          return {
            status: ApiStatus.Resolved,
            data: result.data,
          }
        }
      })
  }
}
