import { AxisIllustrationProps } from '@axiscommunications/fluent-illustrations'
import {
  Body2,
  Button,
  Title3,
  makeStyles,
  mergeClasses,
  tokens,
} from '@fluentui/react-components'
import React, { ReactElement } from 'react'

const useStyles = makeStyles({
  root: {
    maxWidth: '420px',
    minWidth: '160px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: tokens.spacingVerticalS,
  },
  illustration: {
    maxWidth: '160px',
    maxHeight: '160px',
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: tokens.spacingVerticalL,
  },
  text: {
    color: tokens.colorNeutralForeground2,
    textAlign: 'center',
  },
  button: {
    width: 'fit-content',
  },
})

type IllustrationProps = {
  title?: string
  description?: string | ReactElement
  button?: { onClick: () => void; text: string }
  illustration: React.FC<AxisIllustrationProps>
}

type IllustrationTitleProps = { text: string }

type IllustrationDescriptionProps = { text: string | ReactElement }

type IllustrationButtonProps = { onClick: () => void; text: string }

export function Illustration({
  title,
  description,
  button,
  illustration,
}: IllustrationProps) {
  const styles = useStyles()
  const hasContent = title || description || button ? true : false

  const Illustration: React.FC<AxisIllustrationProps> = illustration

  return (
    <div className={mergeClasses(styles.root, styles.container)}>
      <Illustration
        data-testid={'illustration-illustration'}
        className={styles.illustration}
      />
      {hasContent && (
        <div className={mergeClasses(styles.root, styles.contentContainer)}>
          {(title || description) && (
            <div className={styles.container}>
              {title && <IllustrationTitle text={title} />}
              {description && <IllustrationDescription text={description} />}
            </div>
          )}
          {button && (
            <IllustrationButton onClick={button.onClick} text={button.text} />
          )}
        </div>
      )}
    </div>
  )
}

function IllustrationTitle({ text }: IllustrationTitleProps) {
  const styles = useStyles()
  return (
    <Title3 data-testid={'illustration-title'} className={styles.text}>
      {text}
    </Title3>
  )
}

function IllustrationDescription({ text }: IllustrationDescriptionProps) {
  const styles = useStyles()
  return (
    <Body2 data-testid={'illustration-description'} className={styles.text}>
      {text}
    </Body2>
  )
}

function IllustrationButton({ onClick, text }: IllustrationButtonProps) {
  const styles = useStyles()
  return (
    <Button
      data-testid={'illustration-button'}
      className={styles.button}
      appearance="outline"
      size="medium"
      onClick={onClick}
    >
      {text}
    </Button>
  )
}
