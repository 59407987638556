import { AuthProvider, useAuth } from '@axteams-one/auth-provider'
import { useApi } from '@axteams-one/bws-cloud-discovery/react'
import {
  CompoundRetriever,
  LocalFlagRetriever,
  OrganizationalFlagRetriever,
} from '@axteams-one/bws-cloud-flags'
import { FlagProvider } from '@axteams-one/bws-cloud-flags/react'
import { ReactNode } from 'react'

import {
  OrganizationProvider,
  useOrganizationId,
} from '../components/OrganizationProvider'
import { config, isDev } from '../config'

type AuthenticatedRouteProps = {
  readonly children: React.ReactNode
}

/**
 * Renders a route inside the providers required for an authenticated view.
 */
export function AuthenticatedRoute({ children }: AuthenticatedRouteProps) {
  const env = config.environment === 'prod' ? 'prod' : 'stage'
  return (
    <AuthProvider
      env={env}
      localDevelopment={isDev(window.location.hostname)}
      baseUrl={config.loginUrl}
    >
      <OrganizationProvider>
        <FlagWrapper>{children}</FlagWrapper>
      </OrganizationProvider>
    </AuthProvider>
  )
}

type FlagWrapperProps = {
  children?: ReactNode
}

function FlagWrapper({ children }: FlagWrapperProps) {
  const bwoApi = useApi('bwo')
  const [organizationId] = useOrganizationId()
  const { refresh } = useAuth()
  const localFlagRetriever = new LocalFlagRetriever(localStorage)
  const flagRetriever =
    organizationId && bwoApi
      ? new CompoundRetriever(
          new OrganizationalFlagRetriever(
            bwoApi.uri,
            organizationId,
            getAccessToken
          ),
          localFlagRetriever
        )
      : localFlagRetriever
  return <FlagProvider flagRetriever={flagRetriever}>{children}</FlagProvider>

  async function getAccessToken(): Promise<string> {
    const response = await refresh()
    const token = response.data?.access_token
    if (!token) {
      throw new Error('Invalid refresh token response')
    }
    return token
  }
}
