import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import resourcesToBackend from 'i18next-resources-to-backend'
import 'react-i18next'
import { initReactI18next } from 'react-i18next'

import { LanguageCode } from './language'

i18n
  .use(
    resourcesToBackend(
      (language: string) => import(`./translation/${language}.json`)
    )
  )
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    ns: ['en'],
    defaultNS: 'en',
    detection: {
      lookupQuerystring: 'lang',
      // Use locally stored language choice, unless it's the first time the user
      // ends up here.
      order: ['localStorage', 'querystring', 'navigator'],
    },
    fallbackLng: LanguageCode.English,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
