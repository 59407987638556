import {
  ClaimSystemDocument,
  ClaimSystemMutation,
  ErrorCode,
} from '../graphql/__generated__/graphql'
import { ApiStatus, InternalErrorCode } from './types'
import { Api, useUrqlClient } from './useUrqlClient'

type ClaimSystemParams = {
  authorizationCode: string
  /**
   * The ARN of the resource group in which the system's resource group should be
   * created.
   */
  resourceParent: string
  systemId: string
  storageLocationId: string
}

export function useClaimSystem() {
  const client = useUrqlClient(Api.Bwo)

  return function claimSystem({
    systemId,
    resourceParent,
    authorizationCode,
    storageLocationId,
  }: ClaimSystemParams) {
    return client
      ?.mutation<ClaimSystemMutation>(ClaimSystemDocument, {
        input: {
          systemID: systemId,
          resourceParent,
          authorizationCode,
          storageLocationID: storageLocationId,
        },
      })
      .toPromise()
      .then((result) => {
        if (result.error) {
          const errorCode =
            (result.error.graphQLErrors[0]?.extensions.code as ErrorCode) ??
            InternalErrorCode.NetworkError
          return {
            status: ApiStatus.Rejected,
            error: errorCode,
          }
        } else {
          return {
            status: ApiStatus.Resolved,
          }
        }
      })
  }
}
