import { PropsWithChildren, createContext, useContext, useState } from 'react'

type OrganizationContextProps = {
  organizationId?: string
  setOrganizationId?: (id: string | undefined) => void
}

const OrganizationContext = createContext<OrganizationContextProps>({})

/**
 * Provide the subtree with a the currently selected organization ID and a
 * function to change the selected organization to a new one.
 */
export function OrganizationProvider({ children }: PropsWithChildren) {
  const [organizationId, setOrganizationId] = useState<string>()

  return (
    <OrganizationContext.Provider value={{ organizationId, setOrganizationId }}>
      {children}
    </OrganizationContext.Provider>
  )
}

/**
 * Get an array where the first element is the selected organization ID and the
 * second a function to set the ID.
 */
export function useOrganizationId(): [
  string | undefined,
  (id: string | undefined) => void,
] {
  const { organizationId, setOrganizationId } = useContext(OrganizationContext)

  if (setOrganizationId === undefined) {
    throw new Error(
      'useOrganizationId must be used within a OrganizationProvider'
    )
  }

  return [organizationId, setOrganizationId]
}
